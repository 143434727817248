import * as React from "react";
import {useEffect, useState} from "react";
import {Message} from "./Conversation";
import Switch from '@mui/material/Switch';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button/Button";
import {SimpleLoader} from "@components/SimpleLoader";
import {ToastModule} from "@intuitionrobotics/thunderstorm/frontend";
import {config} from "../../../config";

function MessageRenderer({message}: { message: Message }) {
    const [showExtraParams, setShowExtraParams] = useState(false); // State to manage visibility

    const shouldShowExtraParamRelated = message.extraParams && showExtraParams;
    return (
        <div
            key={message.id}
            className={`message ${(message.sender).toLowerCase()}-message`}
            onClick={() => {
                if (showExtraParams)
                    return;
                setShowExtraParams(true)
            }}
        >
            {shouldShowExtraParamRelated && <div className={"close"} onClick={() => setShowExtraParams(false)}>X</div>}
            {message.text}
            {message.translatedText && <div className="translated-text">{message.translatedText}</div>}
            {shouldShowExtraParamRelated &&
                <div className="extra-params">
                    {Object.keys(message.extraParams).map(key =>
                        renderExtraParam(key, message.extraParams[key], message.id)
                    )}
                </div>
            }
        </div>
    );
}

function renderExtraParam(key : string, value: any, messageId : string) {
    if (key === "sessionId") {
        const today = new Date();
        const formattedDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
        const query = `SELECT * FROM \`ir-datastore-test.raw_data.speech_processing\` WHERE sessionId = '${value}' AND partDate = '${formattedDate}'`;

        const bigQueryUrl = `https://console.cloud.google.com/bigquery?project=${config.BigQueryModule.dataSet}&ws=!1m0&page=queryeditor`;
        const handleClick = (e: React.MouseEvent) => {
            e.preventDefault();
            navigator.clipboard.writeText(query.trim()).then(() => {
                ToastModule.toastInfo("Query copied to clipboard! Redirecting...", 3000);
                console.log("Query copied to clipboard! You can paste it in the BigQuery editor.");
                setTimeout(() => window.open(bigQueryUrl, '_blank'), 2000);
            }).catch(err => {
                console.error("Failed to copy query to clipboard: ", err);
            });
        };

        return (
            <div key={`${messageId}_${key}`}>
                {key}:
                <a
                    onClick={handleClick}
                    style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                >
                    {value}
                </a>
            </div>
        );
    }
    return (
        <div key={`${messageId}_${key}`}>
            {key}: {value}
        </div>
    );
}

type ChatProps = {
    messages: Message[],
    onToggle: (toggled: boolean) => void,
    onMessageSent: (message: string) => void,
    isToggled: boolean | undefined
};

export function RenderChat({messages, onMessageSent, onToggle, isToggled}: ChatProps) {
    const [typedMessage, setTypedMessage] = useState("");
    const [showInput, setShowInput] = useState(isToggled)
    const [loading, setLoading] = useState(false)

    let t: any;
    const enterPressed = () => {
        t && clearTimeout(t);
        setTypedMessage("");
        onMessageSent(typedMessage);
        setLoading(true);
        t = setTimeout(() => setLoading(false), 3000);
    };

    useEffect(() => {
        setShowInput(isToggled);
    }, [isToggled]);

    useEffect(() => {
        setLoading(false);
        t && clearTimeout(t);
    }, [messages.length]);

    return (
        <div className="conversation-container">
            <div className={"ll_h_c space-between"}>
                <h2>Conversation</h2>
                {isToggled !== undefined && <Switch
                    onClick={() => {
                        setShowInput(s => {
                            onToggle(!s)
                            return !s;
                        })
                    }}
                    checked={showInput}
                    inputProps={{'aria-label': 'controlled'}}
                />}
            </div>
            <div className="message-container">
                {messages.map((message) => <MessageRenderer key={message.id} message={message}/>)}
            </div>
            {showInput && <div className={"ll_h_c space-between"}>
                <TextField
                    id="filled-basic"
                    variant="standard"
                    onChange={(e) => setTypedMessage(e.target.value)}
                    placeholder="Type your message..."
                    onKeyDown={e => e.key === "Enter" && enterPressed()}
                    value={typedMessage}
                />
                <Button
                    variant="contained"
                    onClick={() => enterPressed()}
                >Send</Button>
            </div>}
            {loading && <SimpleLoader backgroundColor={"transparent"} absolute={true}/>}
        </div>)
}
