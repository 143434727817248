import {BaseComponent, ToastModule} from "@intuitionrobotics/thunderstorm/frontend";
import * as React from "react";
import {css} from "emotion";
import {Component_PlaceAutocomplete} from "@components/Component_PlaceAutocomplete";
import {ActivationForm, authType, AuthType, Body_Activation} from "@app/ir-q-app-common/types/activation"
import {OnRequestListener} from "@intuitionrobotics/thunderstorm";
import {inputContainerStyle, labelStyle, NewStyledInput} from "@components/NewStyledInput";
import {errorText, NewStyledDropDown} from "@components/NewStyledDropDown";
import {GenderChoices} from "@components/GenderChoices";
import {DataEnvsModule} from "@modules/DataEnvsModule";
import {DefaultApiDefs} from "@intuitionrobotics/db-api-generator/shared/types";
import {PackageManagerModule, RequestKey_FetchAliases} from "@modules/package-manager/PackageManagerModule";
import {
    validateInternationalPhoneNumber,
    validatorOf3p0,
    validatorOfAlphaNumeric,
    validatorOfAlphaNumericAndDashes,
    validatorOfEmail,
    validatorOfLowerAlphaNumericAndUnderscores,
    validatorOfNumbersDashesAndPlusSignInBeginning,
    validatorOfSomSerial,
    validatorOfSOSerial,
    validatorOfTabletSerial
} from "@app/ir-q-app-common/shared/validations";
import {Html5QrcodePluginFallback} from "@app/ir-q-app-common/app-frontend/components/Html5QrcodePluginFallback";
import {_keys, _values, auditBy} from "@intuitionrobotics/ts-common";
import Select from "react-select";
import {elliqIdRe} from "@app-sp/app-shared/rejexes";
import {UnitsManagerModule} from "@modules/UnitsManagerModule";
import * as moment from "moment";
import {CareCenterModule, RequestKey_FetchCCData} from "@modules/CareCenterModule";
import {config} from "../../../config";
import {getDisplayName} from "@app/ir-q-app-common/types/care-center/user";
import {SimpleLoader} from "@components/SimpleLoader";
import {ActivationsModule, OnActivationRegisterUnitIdWasInUse, OnModal} from "@modules/ActivationsModule";
import {DialogWasInUseUnit} from "./DialogWasInUseUnit";
import {Html5QrcodeResult, Html5QrcodeSupportedFormats} from "html5-qrcode";
import {UnitContext, UserGroupAction, UserGroupsContext} from "../../App";
import {StorageKey_UserEmail} from "@intuitionrobotics/user-account/frontend";
import {DropDownWithAddOption} from "./DropDownWithAddOption";
import {OnMetadataUpdated, UnitsModule} from "@modules/UnitsModule";
import {CONST_KS_SubUserGroup, CONST_KS_UserGroup} from "@app/ir-q-app-common/types/unit-config";
import {Modal, ModalInfo} from "../../components/Modal";
import {SerialNotAvailableError} from "@app/ir-q-app-common/shared/errors";
import {PermissionsComponent, PermissionsFE} from "@intuitionrobotics/permissions/frontend";
import {Url_SupportAdmin} from "../../routes";
import {initUnitsType} from "../units/Page_Units";

export type ActivationDefaultParameters = ActivationForm & {
    aliasName?: string,
    pincode?: string;
    activated?: boolean;
    blame?: string;
    id?: number,
    som_pk?: string | null,
    tablet_pk?: string | null
}

export type ActivationDefaultParametersWithAuthType = Omit<ActivationDefaultParameters, "authType"> & {
    authType: AuthType
}


type StateError = {
    [k: string]: string | undefined
};
type State = ActivationForm & {
    errors: StateError,
    showCalendar: boolean,
    scanQrCode: boolean,
    scanReplacementTablet: boolean,
    aliasName: string
    userOnly: boolean
    loading: boolean
    modal?: ModalInfo
    isAdminOverride: boolean
};
type Props = {
    isPopup?: boolean,
    defaultParams?: ActivationDefaultParameters,
    editMode?: boolean,
    renameUnitId?: string,
    onChange?: (contact: ActivationForm) => void
    onSubmit?: (activationData: Body_Activation, skipHistoryCheck?: boolean) => void
    userOnly?: boolean
    hideUserOnlyToggle?: boolean
}
type Data = keyof ActivationForm

const flags = require("@res/flags.json");
const rowWidth = 300;
export const rowHeight = 40;
export const rowClass = css({
    width: rowWidth
})
const wrapperSpaced = css({
    paddingTop: "3%",
    paddingRight: "10%",
    paddingLeft: "10%",
    alignItems: "flex-start",
    height: "100%"
})
const multipleRow = css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: rowWidth + "px"
})
const btn = css({
    borderRadius: 10,
    marginTop: "15px",
    color: 'white',
    width: "300px",
    height: rowHeight + "px",
    backgroundColor: '#487CFF',
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    cursor: "pointer"
})
const qrScannerCss = css({
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
})
const qrScannerCssWrapper = css({
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "black",
    zIndex: 100000
})
// const qrScannerWidth = `qr-scanner-width_responsive`;
const blockClassName = `match_height match_width ll_v_c width_responsive`;
const icon_qrcode_scanner = require('@res/images/icon__qrcodeScanner.svg')


export class ActivationFormComponent
    extends BaseComponent<Props, State>
    implements OnRequestListener, OnActivationRegisterUnitIdWasInUse, OnMetadataUpdated, OnModal {
    private firstEnter: boolean = false;

    private baseMandatoryFields: Set<keyof ActivationForm> = new Set([
        "userGroup",
        "alias",
        "unitId",
        "authType",
        "firstName",
        "lastName",
        "gender",
        "areaCode",
        "phoneNumber",
        "country",
        "state",
        "city",
        "organizationId"
    ]);

    private unitMandatoryFields: Set<keyof ActivationForm> = new Set(this.baseMandatoryFields).add("somSerial").add("tabletSerial");

    private getMandatoryFields = (): Set<keyof ActivationForm> => {
        if (this.state.userOnly)
            return this.baseMandatoryFields;

        return this.unitMandatoryFields;
    };

    __onRequestCompleted = (key: string, success: boolean, requestData?: string | undefined) => {
        switch (key) {
            case DataEnvsModule.getRequestKey(DefaultApiDefs.Create):
            case DataEnvsModule.getRequestKey(DefaultApiDefs.Query):
            case RequestKey_FetchAliases:
            case RequestKey_FetchCCData:
                return this.forceUpdate();
        }
    };

    __onActivationRegisterUnitIdWasInUse(params: Body_Activation, hasFMContacts: boolean) {
        this.setState({loading: false});
        DialogWasInUseUnit.show(
            params,
            hasFMContacts,
            () => this.setState({unitId: ""}),
            (activationData: Body_Activation, skipHistoryCheck?: boolean) => {
                this.setState({loading: true});
                this.props.onSubmit?.(activationData, skipHistoryCheck);
            });
    }

    __onModal(modalType: string, device?: string, data?: any, isActivation?: boolean) {
        switch (modalType) {
            case SerialNotAvailableError:
                const serialNumber = device === 'som' ? this.state.somSerial : (device === 'tablet' ? this.state.tabletSerial : "");
                const permitted = PermissionsFE.doesUserHavePermissions(Url_SupportAdmin); // This will always return because permissions for this url are evaluated in the index
                const closeButton = {
                    label: "Close", onClick: () => this.setState({modal: undefined})
                };

                this.setState({
                    loading: false,
                    modal: {
                        title: "Serial Not Available",
                        body: `The${device ? ` ${device}` : ""} serial number ${serialNumber ? ` ${serialNumber}` : ""} is not in our inventory, please contact support`,
                        onClose: () => this.setState({modal: undefined}),
                        buttons: permitted ? [closeButton, {
                            label: "Override", onClick: () => {
                                if (!isActivation)
                                    ActivationsModule.updateWithMetadata(data, true, () => CareCenterModule.fetchCCData(true));
                                else
                                    ActivationsModule.activate(data, data.skipHistoryCheck, true, () => CareCenterModule.fetchCCData(true));

                                this.setState({modal: undefined, loading: true})
                            }
                        }] : [closeButton]
                    }
                });
                break;
        }
    }

    componentDidMount() {
        CareCenterModule.fetchCCData();
        UnitsModule.queryUnitMetadata({dataKey: {$in: [CONST_KS_UserGroup, CONST_KS_SubUserGroup]}});
    }

    constructor(props: Props) {
        super(props);

        const defaultParams = {...this.props.defaultParams};
        if (defaultParams.phoneNumber) {
            defaultParams.phoneNumber = defaultParams.phoneNumber.replace(/-/g, "");
            defaultParams.phoneNumber = defaultParams.phoneNumber.replace(this.props.defaultParams?.areaCode || "", "");
        }

        if (defaultParams.birthday) {
            const utcDate = new Date(defaultParams.birthday);
            // @ts-ignore
            defaultParams.birthday = utcDate.toISOString().slice(0, 10); // toISOString is always UTC
        }

        this.state = {
            birthday: 0,
            errors: {},
            showCalendar: false,
            scanQrCode: false,
            scanReplacementTablet: false,
            aliasName: "",
            userOnly: !!this.props.userOnly,
            areaCode: config.ContactsModule.defaultAreaCode,
            loading: false,
            isAdminOverride: false,
            ...defaultParams
        }

        PackageManagerModule.fetchAliases();
    }

    __onMetadataUpdated(): void {
        this.forceUpdate();
    }

    handleScanSuccess = (_serial: string, result: Html5QrcodeResult) => {
        if (!_serial)
            return;

        let serial: string | undefined = undefined;
        const format = result.result.format?.format;
        switch (format) {
            case Html5QrcodeSupportedFormats.QR_CODE:
                try {
                    const device = JSON.parse(_serial);
                    serial = device.serial;
                } catch (e) {
                    // console.info(e);
                    ///PRODUCT=ELQ30S2402A00018,MFGR=ELQ30RS2404A00007,ROBOT=ELQ30SS2404A00007
                    const reg = _serial.match(/^PRODUCT=(ELQ30.*),MFGR/);
                    if (reg) {
                        serial = reg[1];
                    } else
                        serial = _serial;
                }
                break;
            case Html5QrcodeSupportedFormats.CODE_128:
                serial = _serial;
        }

        if (!serial) {
            this.logError("Not a valid scanning result" + _serial);
            this.logError(result);
            return ToastModule.toastError(`Not a valid barcode...` + serial);
        }

        console.log("Scanned: " + serial)
        if (Number.isInteger(+serial) && serial.length == 8) {
            this.setState(s => ({
                tabletSerial: serial,
                scanReplacementTablet: s.scanReplacementTablet ? false : s.scanReplacementTablet,
                scanQrCode: false
            }));
        }

        if (serial.length == 18)
            return this.setState({somSerial: serial, scanQrCode: false});

        if (serial.startsWith("ELQ30") && serial.length == 16)
            return this.setState({somSerial: serial, tabletSerial: serial, scanQrCode: false});

        return ToastModule.toastError(`Not a valid barcode...` + serial);
    }

    private renderButton_QRScanner = () => (
        <div className={qrScannerCssWrapper}>
            <div className={qrScannerCss}>
                <div style={{color: "white", cursor: "pointer", marginBottom: "5px"}}
                     onClick={() => this.setState({scanQrCode: false})}>Close Scanner
                </div>
                <Html5QrcodePluginFallback qrCodeSuccessCallback={this.handleScanSuccess}/>
                {/*<QrReader*/}
                {/*    delay={300}*/}
                {/*    onError={this.handleScanError}*/}
                {/*    onScan={this.handleScanSuccess}*/}
                {/*    className={qrScannerWidth}*/}
                {/*/>*/}
            </div>
        </div>
    );

    private alphaNumericTest(value: any) {
        const testResult = !value ? true : validatorOfAlphaNumeric.test(value);
        if (!testResult)
            ToastModule.toastError("Please type only letters or digits");

        return testResult;
    }

    lowerAlphaNumericAndUnderscoreTest(value: any) {
        const testResult = !value ? true : validatorOfLowerAlphaNumericAndUnderscores.test(value);
        if (!testResult)
            ToastModule.toastError("Please type only lowercase letters or digits or underscores");

        return testResult;
    }

    // tabletSerialTest = (value: any) => {
    //     if (value && typeof value === "string" && (value.length < 5 || this.is3p0(value)))
    //         return this.alphaNumericTest(value);
    //
    //     const testResult = !value ? true : validatorOfNumeric.test(value);
    //     if (!testResult)
    //         ToastModule.toastError("Please type only digits");
    //
    //     return testResult;
    // };

    private alphanumericAndDashesTest(value: any) {
        const testResult = !value ? true : validatorOfAlphaNumericAndDashes.test(value);
        if (!testResult)
            ToastModule.toastError("Please type only numbers, digits or dashes");

        return testResult;
    }

    private lettersDashesAndPlusSignInBeginningTest(value: any) {
        const testResult = !value ? true : validatorOfNumbersDashesAndPlusSignInBeginning.test(value);
        if (!testResult)
            ToastModule.toastError("Please type valid phone number convention, for example: +972111111111");

        return testResult;
    }

    private isUnitIdExist(unitId?: string) {
        if (this.props.renameUnitId && unitId === this.props.renameUnitId) {
            ToastModule.toastError(`You didn't change the unit id, "${unitId}" is the same as original id`);
            return true;
        }

        if (!unitId || this.props.editMode)
            return false;

        const existAlready = UnitsManagerModule.getUnitBasic(unitId);
        if (existAlready)
            ToastModule.toastError(`Unit Id: "${unitId}" is already exist, please input another Unit Id`);

        return existAlready !== undefined;
    }

    private isReplacementSerialIsSameAsTabletSerial() {
        if (!this.state.replacementTabletSerial || !this.state.tabletSerial)
            return false;

        if (this.state.replacementTabletSerial === this.state.tabletSerial) {
            ToastModule.toastError(`The replacement tablet serial number is the same as tablet serial number`);
            return true;
        }

        return false;
    }

    private isSerialExistInOtherUnit(units: string[], unitId?: string, serial?: string) {
        if (!serial)
            return false;

        const existAlready = units.find(u => {
            if (this.props.renameUnitId && u === this.props.renameUnitId)
                return false;

            const unit = UnitsManagerModule.getUnitBasic(u);
            return (!unitId || u !== unitId) && unit && (unit.somSerial === serial || unit.tabletSerial === serial);
        });

        if (existAlready)
            ToastModule.toastError(`The serial number ${serial} is already used by unit ${existAlready}`);

        return existAlready !== undefined;
    }

    render() {
        if (this.firstEnter) {
            const pointer = document.getElementById("unitId");
            pointer?.focus();
            this.firstEnter = false;
        }

        return <UnitContext.Consumer>
            {units => {
                const unitList = [...Array.from(units.pairedUnits), ...Array.from(units.activatedUnits)];
                return <div className="overflow-y match_width stacking ll_v_c justify-center">
                    <div className={`justify-even ll_hv_c media_v_c display_inline_for_mobile ${wrapperSpaced}`}>
                        <div className={blockClassName}>
                            {this.renderToggle()}
                            {this.renderSkipSerials()}
                            {this.state.scanQrCode && this.renderButton_QRScanner()}
                            {this.renderUserGroup()}
                            {this.renderSubUserGroup()}
                            {this.renderAlias()}
                            {this.renderField(unitList, "unitId", "300px", undefined, this.alphanumericAndDashesTest)}
                            {this.renderAuthType()}
                            {!this.state.userOnly && <>
                                <div className={`ll_h_c ${multipleRow}`}>
                                    {this.renderField(unitList, "somSerial", "250px", undefined, this.alphaNumericTest)}
                                    <img onClick={() => {
                                        this.setState({scanQrCode: true})
                                    }} style={{cursor: "pointer"}} width={rowHeight + "px"} height={rowHeight + "px"}
                                         src={icon_qrcode_scanner}/>
                                </div>
                                <div className={`ll_h_c ${multipleRow}`}>
                                    {this.renderField(unitList, "tabletSerial", "250px", undefined, this.alphaNumericTest)}
                                    <img onClick={() => {
                                        this.setState({scanQrCode: true})
                                    }} style={{cursor: "pointer"}} width={rowHeight + "px"} height={rowHeight + "px"}
                                         src={icon_qrcode_scanner}/>
                                </div>
                                {this.renderReplacementTabletSerial(unitList)}
                            </>}
                            {this.renderField(unitList, "firstName", "300px", value => value)}
                        </div>
                        <div className={blockClassName}>
                            {this.renderField(unitList, "lastName", "300px", value => value)}
                            {this.renderField(unitList, "middleName", "300px", value => value)}
                            {this.renderCCOrg()}
                            {this.renderCCPhysician()}
                            {this.renderCCCareManager()}
                            {this.renderGender()}
                            {this.renderField(unitList, "birthday")}
                            <div className={`ll_h_c ${multipleRow}`}>
                                {this.renderAreaCodeField()}
                                {this.renderField(unitList, "phoneNumber", "155px", value => {
                                    let newVal = value.replace(/-/g, "").trim();
                                    if (this.state.areaCode)
                                        newVal = newVal.replace(this.state.areaCode, "");

                                    return newVal;
                                }, this.lettersDashesAndPlusSignInBeginningTest)}
                            </div>
                            {this.renderField(unitList, "email")}
                        </div>
                        <div className={blockClassName}>
                            {this.renderAddressAutoComplete()}
                            {this.renderField(unitList, "country", "300px", value => value)}
                            {this.renderField(unitList, "state", "300px", value => value)}
                            {this.renderField(unitList, "region", "300px", value => value)}
                            {this.renderField(unitList, "city", "300px", value => value)}
                            {this.renderField(unitList, "streetAddress", "300px", value => value)}
                            {this.renderField(unitList, "extendedAddress", "300px", value => value)}
                            {this.renderField(unitList, "postalCode", "300px", value => value)}
                            {!this.props.isPopup && this.renderSubmitButton(unitList)}
                            <div style={{height: "20px"}}/>
                            {/*fake div for space for mobile*/}
                        </div>
                    </div>
                    {this.renderLoading()}
                    {this.renderModal()}
                </div>
            }}
        </UnitContext.Consumer>
    }

    private renderUserGroup = () => <UserGroupsContext.Consumer>
        {({dispatch, userGroups}) => {
            const val = this.state.userGroup ? {
                key: this.state.userGroup,
                value: this.state.userGroup
            } : {key: "", value: ""};
            return <DropDownWithAddOption
                id={"userGroup"}
                label={"User Group (*)"}
                selectedOption={val}
                options={userGroups.sort((a, b) => a
                    .label
                    .localeCompare(b.label)).map(dataEnv => ({key: dataEnv.label, value: dataEnv.label}))}
                addItem={ug => dispatch(UserGroupAction.CREATE, {
                    _id: ug,
                    audit: auditBy(StorageKey_UserEmail.get()),
                    type: initUnitsType(),
                    label: ug
                })}
                onChange={userGroup => {
                    this.setState(s => {
                        let subUserGroup = s.subUserGroup
                        if (s.userGroup !== userGroup && subUserGroup)
                            subUserGroup = undefined

                        return ({...s, subUserGroup, userGroup: userGroup, errors: {...s.errors, userGroup: undefined}});
                    }, () => this.props.onChange?.(this.state));
                }}
                onBlur={() => {
                    this.setState(s => {
                        const error = this.state.userGroup ? undefined : "Missing user group";
                        return ({...s, errors: {...s.errors, userGroup: error}});
                    })
                }}
                error={this.state.errors.userGroup}
            />;
        }}
    </UserGroupsContext.Consumer>;

    renderReplacementTabletSerial = (units: string[]) => {
        const unitId = this.props.defaultParams?.unitId;
        if (!this.props.editMode || (unitId && UnitsManagerModule.is3Point0Unit(unitId)))
            return null;

        return <div className={`ll_h_c ${multipleRow}`}>
            {this.renderField(units, "replacementTabletSerial", "250px", undefined, this.alphaNumericTest)}
            <img onClick={() => {
                this.setState({scanQrCode: true, scanReplacementTablet: true})
            }} style={{cursor: "pointer"}} width={rowHeight + "px"} height={rowHeight + "px"}
                 src={icon_qrcode_scanner}/>
        </div>
    }

    renderSkipSerials = () => {
        if (this.props.userOnly || this.props.renameUnitId)
            return null;

        return <div className={rowClass} style={{paddingBottom: this.props.editMode ? 0 : "20px"}}>
            <PermissionsComponent
                url={Url_SupportAdmin}
                loadingComponent={() => <></>}
            >
                <div className={'ll_h_c justify-center'} style={{marginTop: this.props.editMode ? "-40px" : "20px"}}>
                    <label>
                        <input
                            type="checkbox"
                            checked={this.state.isAdminOverride}
                            onChange={() => {
                                this.setState(s => ({...s, isAdminOverride: !s.isAdminOverride}));
                            }}
                        />
                        Skip Serials Validation
                    </label>
                </div>

            </PermissionsComponent>
        </div>
    }

    private renderToggle = () => {
        const disabled = !!this.props.editMode || !!this.props.renameUnitId || !config.ActivationModule.userOnlyEnabled
        if (disabled)
            return null;

        if (this.props.userOnly)
            return null;

        if (this.props.hideUserOnlyToggle)
            return null;

        return <div className={rowClass}>
            <div className={multipleRow}>
                <label>
                    <input
                        type="radio"
                        checked={!this.state.userOnly}
                        onChange={() => this.setState(s => ({userOnly: false}))}
                    />
                    Add User + Unit
                </label>
                <label>
                    <input
                        type="radio"
                        checked={this.state.userOnly}
                        onChange={() => this.setState(s => {
                            const newState = {...s, userOnly: true};
                            if (s.authType === "AUTO")
                                newState.authType = undefined;

                            return newState;
                        })}
                    />
                    Add User Only
                </label>
            </div>
        </div>
    }

    private renderAreaCodeField = () => {
        const fieldName = "areaCode";
        const finalResult = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
        // const borderColor = this.state.errors[fieldName] ? inputErrorColor : inputBorderColor;
        const renderLabel = (f: { flag: string, dialCode: string }) => {
            return <div className={"ll_h_c"}>
                <p style={{margin: 0}}>{f.dialCode}</p>
                <img style={{width: 25, height: 25, marginLeft: 10}}
                     src={`data:image/svg+xml;utf8,${encodeURIComponent(f.flag)}`}/>
            </div>;
        }

        const flag = _values(flags).find(_flag => _flag.dialCode === this.state.areaCode);

        return <div style={{width: "135px", height: "81px"}}>
            <div className={labelStyle}>Area Code (*)</div>
            <Select
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        // borderColor: borderColor, //state.isFocused ? 'grey' : 'red',
                        // borderRadius: 10,
                        // border: `0.9px solid ${borderColor}`,
                        // height: "20px",
                        // '&:hover': {
                        //     borderColor: `${borderColor} !important`,
                        //     boxShadow: "none"
                        // },
                        // '&:focus-within': {
                        //     borderColor: `${borderColor} !important`,
                        //     boxShadow: "none"
                        // }
                    }),
                }}
                value={flag ? {label: renderLabel(flag), value: flag.dialCode} : undefined}
                options={_values(flags).sort((f1, f2) => f1.dialCode - f2.dialCode).map(f => ({
                    label: renderLabel(f),
                    value: f.dialCode
                }))}
                onChange={(item) => {
                    const trimmedVal = item?.value.trim();
                    this.setState(state => {
                        return ({
                            ...state,
                            phoneNumber: state.phoneNumber?.replace(trimmedVal, ""),
                            areaCode: trimmedVal,
                            errors: {...state.errors, [fieldName]: undefined}
                        });
                    }, () => this.props.onChange?.(this.state));
                }}
                onBlur={() => {
                    if (!this.getMandatoryFields().has(fieldName))
                        return;

                    this.setState(s => {
                        let trimmedVal = s[fieldName];
                        if (typeof trimmedVal === 'string')
                            trimmedVal = trimmedVal?.trim();

                        const error = trimmedVal ? undefined : "Missing " + finalResult.toLowerCase();
                        return ({...s, [fieldName]: trimmedVal, errors: {...s.errors, [fieldName]: error}});
                    })
                }}
                isSearchable={true}
                placeholder=""
            />
            {this.state.errors[fieldName] && <span className={errorText}>*{this.state.errors[fieldName]}</span>}
        </div>
    };

    toastIfError(fieldName?: Data): Partial<StateError> | void {
        let newState: Partial<StateError> = {};
        if (!fieldName || fieldName === "phoneNumber") {
            const correctPhoneNumber = this.handlePhoneNumberFormat();
            if (correctPhoneNumber && !validateInternationalPhoneNumber.test(correctPhoneNumber)) {
                newState = {phoneNumber: "Invalid phone number"};
                ToastModule.toastError(`please enter the phone number in international format, for example: +972111111111`);
            }
        }

        if ((!fieldName || fieldName === "unitId") && this.state.unitId && !elliqIdRe.test(this.state.unitId)) {
            newState = {...newState, unitId: "Invalid unitId"};
            ToastModule.toastError(`please enter a valid unit name convention, like this: ir-group-name`);
        }

        if ((!fieldName || fieldName === "email") && this.state.email && !validatorOfEmail.test(this.state.email)) {
            newState = {...newState, email: "Invalid email"};
            ToastModule.toastError(`please enter valid email format, for example: name@gmail.com`);
        }

        if ((!fieldName || fieldName === "tabletSerial") && this.state.tabletSerial && !this.state.isAdminOverride) {
            if (this.is3p0(this.state.tabletSerial)) {
                if (!validatorOf3p0.test(this.state.tabletSerial)) {
                    newState = {...newState, tabletSerial: "Invalid tablet serial"};
                    ToastModule.toastError(`Invalid Serial number for 3p0: 16 alphanumerical characters (uppercase) starting with ELQ30, for example: ELQ30S2343PT5008`);
                }
            } else if (this.isSO(this.state.tabletSerial)) {
                if (!validatorOfSOSerial.test(this.state.tabletSerial)) {
                    newState = {...newState, tabletSerial: "Invalid tablet serial"};
                    ToastModule.toastError(`Invalid Serial number for SO: 32 alphanumerical characters (lowercase), for example: d370681e44254978ab99e38d5c0c2f09`);
                }
            } else if (!validatorOfTabletSerial.test(this.state.tabletSerial)) {
                newState = {...newState, tabletSerial: "Invalid tablet serial"};
                ToastModule.toastError(`Invalid Tablet Serial number: 8 digits, for example: 12345678`);
            }
        }

        if ((!fieldName || fieldName === "replacementTabletSerial") && this.state.replacementTabletSerial) {
            if (this.is3p0(this.state.replacementTabletSerial)) {
                if (!validatorOf3p0.test(this.state.replacementTabletSerial)) {
                    newState = {...newState, replacementTabletSerial: "Invalid tablet serial"};
                    ToastModule.toastError(`Invalid Serial number for 3p0: 16 alphanumerical characters (uppercase) starting with ELQ30, for example: ELQ30S2343PT5008`);
                }
            } else if (this.isSO(this.state.replacementTabletSerial)) {
                if (!validatorOfSOSerial.test(this.state.replacementTabletSerial)) {
                    newState = {...newState, replacementTabletSerial: "Invalid tablet serial"};
                    ToastModule.toastError(`Invalid Serial number for SO: 32 alphanumerical characters (lowercase), for example: d370681e44254978ab99e38d5c0c2f09`);
                }
            } else if (!validatorOfTabletSerial.test(this.state.replacementTabletSerial)) {
                newState = {...newState, replacementTabletSerial: "Invalid tablet serial"};
                ToastModule.toastError(`Invalid Tablet Serial number: 8 digits, for example: 12345678`);
            }
        }

        if ((!fieldName || fieldName === "somSerial") && this.state.somSerial && !this.state.isAdminOverride) {
            if (this.is3p0(this.state.somSerial)) {
                if (!validatorOf3p0.test(this.state.somSerial)) {
                    newState = {...newState, somSerial: "Invalid som serial"};
                    ToastModule.toastError(`Invalid Serial number for 3p0: 16 alphanumerical characters (uppercase) starting with ELQ30, for example: ELQ30S2343PT5008`);
                }
            } else if (this.isSO(this.state.somSerial)) {
                if (!validatorOfSOSerial.test(this.state.somSerial)) {
                    newState = {...newState, somSerial: "Invalid som serial"};
                    ToastModule.toastError(`Invalid Serial number for SO: 32 alphanumerical characters (lowercase), for example: d370681e44254978ab99e38d5c0c2f09`);
                }
            } else if (!validatorOfSomSerial.test(this.state.somSerial)) {
                newState = {...newState, somSerial: "Invalid som serial"};
                ToastModule.toastError(`Invalid Som Serial number: 18 alphanumerical characters (uppercase) for example: ABCD1234EFGH567890`);
            }
        }

        if (Object.keys(newState).length > 0)
            return newState;
    }

    private renderSubmitButton = (units: string[]) => {
        return <div
            className={btn}
            onClick={() => {
                if (this.isUnitIdExist(this.state.unitId))
                    return;

                if (this.isSerialExistInOtherUnit(units, this.state.unitId, this.state.somSerial))
                    return;

                if (this.isSerialExistInOtherUnit(units, this.state.unitId, this.state.tabletSerial))
                    return;

                if (this.isSerialExistInOtherUnit(units, this.state.unitId, this.state.replacementTabletSerial) || this.isReplacementSerialIsSameAsTabletSerial())
                    return;

                const {isAdminOverride: _,loading, userOnly, errors, showCalendar, scanQrCode, scanReplacementTablet, aliasName, ...rest} = this.state;
                this.logInfo("Is pre-activate: " + userOnly);
                let requireFieldEmpty = false;
                for (const mandatoryField of this.getMandatoryFields()) {
                    const restElement = rest[mandatoryField];
                    if (!restElement) {
                        ToastModule.toastError(`Missing field ${mandatoryField}, please fill in before submitting`);
                        let finalResult = mandatoryField.replace(/([A-Z])/g, " $1");
                        finalResult = finalResult.charAt(0).toUpperCase() + finalResult.slice(1);
                        this.setState(state => {
                            return ({
                                ...state,
                                errors: {...state.errors, [mandatoryField]: "Missing " + finalResult.toLowerCase()}
                            });
                        });

                        requireFieldEmpty = true;
                    }
                }

                if (requireFieldEmpty)
                    return;

                const failed = this.toastIfError();
                if (failed) {
                    this.setState(s => ({...s, errors: {...s.errors, ...failed}}));
                    return;
                }

                const at = this.state.authType;
                if (!at)
                    return;

                const activationData = {
                    ...rest,
                    phoneNumber: this.handlePhoneNumberFormat(),
                    gender: this.state.gender?.toLowerCase() === "other" ? "" : this.state.gender,
                    authType: at
                };
                _keys(activationData).forEach(activationDataKey => {
                    const value = activationData[activationDataKey];
                    if (value !== undefined && typeof value === 'string') {
                        // @ts-ignore
                        activationData[activationDataKey] = value.trim();
                    }
                });

                if (activationData.birthday)
                    activationData.birthday = moment.utc(new Date(activationData.birthday)).valueOf();

                this.setState({loading: true})
                this.props.onSubmit?.(activationData as Body_Activation);
            }}>
            <div>{this.props.editMode || this.props.renameUnitId ? "Save" : "Submit"}</div>
        </div>;
    };

    private handlePhoneNumberFormat = () => {
        const phoneNumber = this.state.phoneNumber;
        const areaCode = this.state.areaCode;
        if (!phoneNumber || !areaCode)
            return;

        let fullPhoneNumber = phoneNumber.replace(areaCode, "");
        if (fullPhoneNumber.startsWith("0"))
            fullPhoneNumber = fullPhoneNumber.replace("0", "")

        fullPhoneNumber = `${areaCode}${fullPhoneNumber}`
        return fullPhoneNumber.trim();

    }

    private renderGender = () => {
        const fieldName = "gender";
        return <div className={rowClass}>
            <GenderChoices
                value={this.state[fieldName] ? String(this.state[fieldName]) : ''}
                genderList={["MALE", "FEMALE"]}
                label={"Gender (*)"}
                error={this.state.errors[fieldName]}
                onChange={(val: string) => {
                    const trimmedVal = val.trim();
                    this.setState(s => {
                        return ({
                            ...s,
                            [fieldName]: val === "Other" ? "" : trimmedVal.toUpperCase(),
                            errors: {...s.errors, [fieldName]: undefined}
                        });
                    }, () => this.props.onChange?.(this.state));
                }}
            />
        </div>
    }

    private renderField = (units: string[], fieldName: Data, width?: string, valueProcessor?: (v: any) => any, valueValidator?: (v: any) => boolean) => {
        const result = fieldName.replace(/([A-Z])/g, " $1");
        const displayNotMandatoryNote = !this.getMandatoryFields().has(fieldName) && fieldName !== "birthday";
        const finalResult = fieldName === 'birthday' ? 'Birthdate' : fieldName === 'somSerial' ? 'SOM Serial' : result.charAt(0).toUpperCase() + result.slice(1);
        return <div className={rowClass} style={{width}}>
            <NewStyledInput
                id={fieldName}
                key={fieldName + "wrapper"}
                label={finalResult + (displayNotMandatoryNote ? "" : " (*)")}
                type={fieldName !== 'birthday' ? 'text' : 'date'}
                value={this.state[fieldName] ? String(this.state[fieldName]) : ''}
                onChange={(val: string) => {
                    const processedVal = valueProcessor ? valueProcessor(val) : val.trim();
                    if (valueValidator && !valueValidator(val))
                        return;

                    const errorToPatch: StateError = {[fieldName]: undefined}
                    const stateToPatch: Partial<State> = {[fieldName]: processedVal};
                    if (["somSerial", "tabletSerial"].includes(fieldName) && this.is3p0(processedVal)) {
                        const string = fieldName === "somSerial" ? "tabletSerial" : "somSerial";
                        stateToPatch[string] = processedVal;
                        errorToPatch[string] = undefined;
                    }

                    this.setState(s => {
                        return ({...s, ...stateToPatch, errors: {...s.errors, ...errorToPatch}});
                    }, () => this.props.onChange?.(this.state));
                }}
                onBlur={() => {
                    if (fieldName === "unitId" && this.isUnitIdExist(this.state.unitId))
                        return;

                    if (fieldName === "somSerial" && this.isSerialExistInOtherUnit(units, this.state.unitId, this.state.somSerial))
                        return;

                    if (fieldName === "tabletSerial" && this.isSerialExistInOtherUnit(units, this.state.unitId, this.state.tabletSerial))
                        return;

                    if (fieldName === "replacementTabletSerial" && (this.isSerialExistInOtherUnit(units, this.state.unitId, this.state.replacementTabletSerial) || this.isReplacementSerialIsSameAsTabletSerial()))
                        return;

                    this.setState(s => {
                        let trimmedVal = s[fieldName];
                        const newErrorState = this.toastIfError(fieldName);
                        if (typeof trimmedVal === 'string')
                            trimmedVal = trimmedVal?.trim();
                        const required = this.getMandatoryFields().has(fieldName);
                        const field = finalResult.toLowerCase();
                        const error = !required || trimmedVal ? undefined : "Missing " + (field === 'birthday' ? 'birthdate' : field);
                        return ({...s, [fieldName]: trimmedVal, errors: {...s.errors, [fieldName]: error, ...newErrorState}});
                    })
                }}
                error={this.state.errors[fieldName]}
                disabled={fieldName === "unitId" && this.props.editMode}
                height={rowHeight}
            />
        </div>;
    };

    private is3p0 = (processedVal: string) => processedVal.startsWith("ELQ30");

    private isSO = (processedVal: string) => processedVal.length === 32;

    private renderAddressAutoComplete = () => {
        const htmlFor = 'address';
        return <div className={rowClass}>
            <div className={labelStyle}>{`Address (Autocomplete)`}</div>
            <Component_PlaceAutocomplete
                id={htmlFor}
                className={`${inputContainerStyle} ${css({width: "300px", height: "41px"})}`}
                onAutocomplete={({city, country, streetAddress, state, region, extendedAddress, postalCode}) => {
                    const existErrors = this.state.errors
                    const errors = {
                        ...this.state.errors,
                        'city': city ? undefined : existErrors['city'],
                        'country': city ? undefined : existErrors['country'],
                        'streetAddress': city ? undefined : existErrors['streetAddress'],
                        'state': city ? undefined : existErrors['state'],
                        'region': city ? undefined : existErrors['region'],
                        'extendedAddress': city ? undefined : existErrors['extendedAddress'],
                        'postalCode': city ? undefined : existErrors['postalCode']
                    };
                    this.setState({
                        city,
                        country,
                        streetAddress,
                        state,
                        region,
                        extendedAddress,
                        postalCode,
                        errors
                    }, () => this.props.onChange?.(this.state));
                }}
            />
        </div>;
    };

    private renderAlias = () => {
        const fieldName = 'alias';
        const notSelectedVal = {key: "", value: ""};
        return <div className={rowClass}>
            <NewStyledDropDown
                id={`alias`}
                chosenOption={this.state.alias ? {key: this.state.alias, value: this.state.aliasName} : notSelectedVal}
                label={"Alias (*)"}
                onChange={(sel) => {
                    const chosenOption = sel.target.options[sel.target.selectedIndex];
                    const aliasId = chosenOption.value;
                    const aliasName = chosenOption.text;

                    this.setState(s => {
                        return ({...s, alias: aliasId, aliasName, errors: {...s.errors, [fieldName]: undefined}});
                    }, () => this.props.onChange?.(this.state));
                }
                }

                onBlur={() => {
                    if (!this.getMandatoryFields().has(fieldName))
                        return;

                    this.setState(s => {
                        const error = this.state.alias ? undefined : "Missing alias";
                        return ({...s, errors: {...s.errors, [fieldName]: error}});
                    })
                }}
                options={Object.values(PackageManagerModule.getAliases()).sort((a, b) => a.name.localeCompare(b.name)).map(alias => {
                    return {key: alias.id, value: alias.name}
                })}
                error={this.state.errors[fieldName]}
            />
        </div>;
    };

    private renderAuthType = () => {
        const fieldName = 'authType';
        const notSelectedVal = {key: "", value: ""};
        const entries = Object.entries(authType).filter(([_, v]) => {
            if (!this.state.userOnly)
                return true;

            return v !== authType.AUTO;
        });
        return <div className={rowClass}>
            <NewStyledDropDown
                id={`authType`}
                chosenOption={this.state.authType ? {
                    key: this.state.authType,
                    value: authType[this.state.authType]
                } : notSelectedVal}
                label={"Authentication Type (*)"}
                onChange={(sel) => {
                    const k = sel.target.value as AuthType;
                    if (!k)
                        return;

                    if (k === "BIRTHDAY" && !this.state.birthday) {
                        ToastModule.toastError("Choose birthday first");
                        return;
                    }

                    this.setState(s => {
                        return ({...s, authType: k, errors: {...s.errors, [fieldName]: undefined}});
                    }, () => this.props.onChange?.(this.state));
                }
                }

                onBlur={() => {
                    if (!this.getMandatoryFields().has(fieldName))
                        return;

                    this.setState(s => {
                        const error = this.state.authType ? undefined : "Missing auth type";
                        return ({...s, errors: {...s.errors, [fieldName]: error}});
                    })
                }}
                options={entries.map(([k, v]) => {
                    return {key: k, value: v}
                })}
                error={this.state.errors[fieldName]}
            />
        </div>
    }

    private renderCCOrg = () => {
        const organizations = CareCenterModule.getOrganizations();
        const fieldName = "organizationId";
        const options = organizations ? organizations.map(({_id, name}) => {
            return {key: _id, value: name}
        }) : [{key: "loading", value: "Loading..."}];
        const chosenOption = options.find(({key}) => key === this.state[fieldName]) || {key: "loading", value: "Loading..."};
        return <div className={rowClass}>
            <NewStyledDropDown
                id={`cc-organization`}
                chosenOption={chosenOption}
                label={"Care Center Organization (*)"}
                onChange={(sel) => {
                    const k = sel.target.value;
                    if (!k)
                        return;

                    this.setState(s => {
                        return ({...s, [fieldName]: k, errors: {...s.errors, [fieldName]: undefined}});
                    }, () => this.props.onChange?.(this.state));
                }}
                onBlur={() => {
                    if (!this.getMandatoryFields().has(fieldName))
                        return;

                    this.setState(s => {
                        const error = this.state[fieldName] ? undefined : "Missing CC Organization";
                        return ({...s, errors: {...s.errors, [fieldName]: error}});
                    })
                }}
                options={options}
                error={this.state.errors[fieldName]}
            />
        </div>;
    };


    private renderCCPhysician = () => {
        const physicians = CareCenterModule.getPhysicians();
        const options = physicians ? physicians.map(({_id, last, title}) => {
            return {key: _id, value: getDisplayName(last, title)}
        }) : [{key: "loading", value: "Loading..."}];
        const chosenOption = options.find(o => o.key === this.state.physicianId) || {key: "loading", value: "Loading..."};
        return <div className={rowClass}>
            <NewStyledDropDown
                id={`cc-physician`}
                chosenOption={chosenOption}
                label={"Care Center Physician"}
                onChange={(sel) => {
                    const k = sel.target.value;
                    if (!k)
                        return;

                    this.setState(s => {
                        return ({...s, physicianId: k, errors: {...s.errors, physicianId: undefined}});
                    }, () => this.props.onChange?.(this.state));
                }}
                onBlur={() => {
                    if (!this.getMandatoryFields().has("physicianId"))
                        return;

                    this.setState(s => {
                        const error = this.state.physicianId ? undefined : "Missing CC Physician";
                        return ({...s, errors: {...s.errors, physicianId: error}});
                    })
                }}
                options={options}
                error={this.state.errors.physicianId}
            />
        </div>;
    };

    private renderCCCareManager = () => {
        const careManagers = CareCenterModule.getCareManagers();
        const options = careManagers ? careManagers.map(({_id, last, title}) => {
            return {key: _id, value: getDisplayName(last, title)}
        }) : [{key: "loading", value: "Loading..."}];
        const chosenOption = options.find(o => o.key === this.state.careManagerId) || {key: "loading", value: "Loading..."};
        return <div className={rowClass}>
            <NewStyledDropDown
                id={`cc-care-manager`}
                chosenOption={chosenOption}
                label={"Care Center Care Manager"}
                onChange={(sel) => {
                    const k = sel.target.value;
                    if (!k)
                        return;

                    this.setState(s => {
                        return ({...s, careManagerId: k, errors: {...s.errors, careManagerId: undefined}});
                    }, () => this.props.onChange?.(this.state));
                }}
                onBlur={() => {
                    if (!this.getMandatoryFields().has("careManagerId"))
                        return;

                    this.setState(s => {
                        const error = this.state.careManagerId ? undefined : "Missing CC Care Manager";
                        return ({...s, errors: {...s.errors, careManagerId: error}});
                    })
                }}
                options={options}
                error={this.state.errors.careManagerId}
            />
        </div>;
    };

    private renderLoading = () => {
        if (!this.state.loading)
            return;
        return <SimpleLoader overlay={true} noText={true}/>;
    };

    private renderSubUserGroup = () => {
        const subUserGroup = this.state.subUserGroup;
        const val = subUserGroup ? {
            key: subUserGroup,
            value: subUserGroup
        } : {key: "", value: ""};
        const metadatas = UnitsModule.getMetadatas();
        const subUserGroups = new Set<string>;
        if (this.state.userGroup) {
            Object.values(metadatas).forEach(metadata => {
                if (metadata.some(m => m.data === this.state.userGroup && m.dataKey === CONST_KS_UserGroup)) {
                    const subMeta = metadata.find(m => m.dataKey === CONST_KS_SubUserGroup);
                    subMeta && subUserGroups.add(subMeta?.data);
                }
            })
        }
        if (subUserGroup && !subUserGroups.has(subUserGroup))
            subUserGroups.add(subUserGroup);

        return <DropDownWithAddOption
            id={"subUserGroup"}
            label={"Sub User Group"}
            selectedOption={val}
            disabled={!this.state.userGroup}
            options={[...subUserGroups].sort((a, b) => a
                .localeCompare(b)).map(v => ({key: v, value: v}))}
            onChange={_v => {
                this.setState(s => {
                    return ({...s, subUserGroup: _v, errors: {...s.errors, subUserGroup: undefined}});
                }, () => this.props.onChange?.(this.state));
            }}
            error={this.state.errors.subUserGroup}
        />
    };

    private renderModal() {
        if (!this.state.modal)
            return null;

        return <Modal {...this.state.modal}/>
    }
}
